import React, { useRef, useState } from "react";
import Swiper,{SwipeRef} from 'react-tiga-swiper';
import 'react-tiga-swiper/dist/index.css';
import style from "./index.module.less";


const SwiperBanner = ()=> {
  const swiperRef = useRef(null);
  const [index, setIndex] = useState();
  const swiperData = [
    require('../../static/image/swiper/banner1.png'),
  ];
  const swipeTo = () => {
    const swiperInstance = swiperRef.current;
    const currPage = index * 1 + '';
    swiperInstance?.swipeTo(parseInt(currPage, 10));
  };

  const prev = () => {
    const swiperInstance = swiperRef.current;
    swiperInstance?.prev();
  };

  const next = () => {
    const swiperInstance = swiperRef.current;
    swiperInstance?.next();
  };

  const onChange = (currPage, prevPage) => {
  };

  const toService = () => {
    window.location.href="https://t.me/pc228";
  }
 
  return (
    <div className={style.SwiperBanner}>
      {/* <div style={{ marginBottom: 20 }}>
        <div className="manu-action">
          <input
            type="number"
            value={index}
            onChange={(e) => setIndex(e.target.value)}
          />
          <span className="btn" onClick={swipeTo}>手动跳转页面(从零开始)</span>
        </div>
        <div className="more-action">
          <span className="btn" onClick={prev}>上一页</span>
          <span className="btn" onClick={next}>下一页</span>
        </div>
      </div> */}
      {/* <Swiper
        
        autoPlay={3000}
        selectedIndex={0}
        showIndicators={true}
        indicator={null}
        dots={null}
        direction="horizontal"
        loop={true}
        touchable={true}
        ref={swiperRef}
        onChange={onChange}
      > */}
      <div className={style.swiperContainer} onClick={()=>toService()}>
        {swiperData.map((item, key) => (
          <img src={item} alt="" key={key} className={style.img}/>
          // <div key={key} style={{backgroundColor: item}}>{key + 1}</div>
        ))}
      </div>
      {/* </Swiper> */}
    </div>
  );
}

export default SwiperBanner;
